body, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, .navbar, .brand, .btn-simple, .alert, a, .td-name, td, button.close {
  font-family: 'Kanit', Arial, sans-serif;
}
body, body > .navbar-collapse .nav li .nav-link p,
.sidebar .nav li .nav-link p,
.sidebar .nav .nav-link, body > .navbar-collapse .nav .nav-link,
.btn, .card label, .table > thead > tr > th, .form-control {
  font-size: 14px;
}
.table > thead > tr > th {
  text-align: center !important;
  font-weight: bold;
  color: inherit;
}
.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  padding: 8px !important;
}
h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  margin: inherit;
}

.sidebar .logo .simple-text, body > .navbar-collapse .logo .simple-text { text-transform: none; font-weight: bold; }
.sidebar .logo, body > .navbar-collapse .logo { border-bottom: none; }
.sidebar::before { opacity: 1; background: #2c2c2c; }
.sidebar::after { display: none; }
.sidebar .nav li .nav-link i, body > .navbar-collapse .nav li .nav-link i { font-size: 20px; line-height: 31px; margin-right: 8px; }
.card label { text-transform: none; }
.modal.show .modal-dialog { transform: none; }

.react-datepicker .anticon { vertical-align: middle; }
.react-datepicker button, .react-datepicker select { border: solid 1px #aeaeae; border-radius: 0.3rem; background: #e0e0e0; }
.react-datepicker__day--outside-month { color: #bbb !important; }
.react-datepicker-wrapper { display: block !important; }

.btn-manage-group .btn-manage:not(:first-child) { margin-left: 5px; }
.btn-manage-group .btn-manage:not(:first-child)::before { content: "|"; margin-right: 5px; }